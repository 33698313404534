:global(#app) {
  .version {
    font-weight: bold;
    text-align: center;
  }

  .wrapper {
    border: none;
    box-shadow: none;
  }

  .thanks {
    text-align: center;
    margin-bottom: 30px;
  }
}
